import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.baseUrl;

  loginApi(body: any) {
    return this.http.post(this.baseUrl + `auth/login`, body)
  }

  signupApi(body: any) {
    return this.http.post(this.baseUrl + `auth/register`, body)
  }


  socialsignupApi(body: any) {
    return this.http.post(this.baseUrl + `auth/socialLogin`, body)
  }


  forgotApi(body: any) {
    return this.http.post(this.baseUrl + `api/forgotPassword`, body)
  }

  verfication(body: any) {
    return this.http.post(this.baseUrl + `api/emailVerification`, body)
  }

  changePassword(body: any) {
    return this.http.post(this.baseUrl + `user/changePassword`, body)
  }

  getlinkedInAccessToken(body: any) {
    return this.http.post(this.baseUrl + `api/getLinkedInToken`, body)
  }

  logoutApi(body: any) {
    return this.http.post(this.baseUrl + `auth/logOut`, body)
  }

  getCountryListApi() {
    return this.http.get(this.baseUrl + `api/getCountries`)
  }

  getRegionistApi(data: any) {
    return this.http.post(this.baseUrl + `api/getRegion`, data)
  }

  getCategoriesApi() {
    return this.http.get(this.baseUrl + `api/getCategories`)
  }

  getProfileApi() {
    return this.http.post(this.baseUrl + `auth/getProfile`, '')
  }

  createProfileApi(body: any) {
    return this.http.post(this.baseUrl + `auth/createProfile`, body)
  }

  createProfileFreelancerApi(body: any) {
    return this.http.post(this.baseUrl + `auth/createProfile`, body)
  }

  addEductionApi(body: any) {
    return this.http.post(this.baseUrl + `auth/addEducation`, body)
  }

  addSkillApi(body: any) {
    return this.http.post(this.baseUrl + `auth/addSkill`, body)
  }

  addPortfolioApi(body: any) {
    return this.http.post(this.baseUrl + `auth/addPortfolio`, body)
  }

  addAvailablityApi(body: any) {
    return this.http.post(this.baseUrl + `auth/addAvailablity`, body)
  }

  addWorkHistoryApi(body: any) {
    return this.http.post(this.baseUrl + `auth/addWork`, body)
  }

  addIdproof(body: any) {
    return this.http.post(this.baseUrl + `auth/uploadIDProof`, body)
  }

  editProfileApi(body: any) {
    return this.http.post(this.baseUrl + `auth/editProfile`, body)
  }

  editEductionApi(body: any) {
    return this.http.post(this.baseUrl + `auth/editEducation`, body)
  }

  editPortfolioApi(body: any) {
    return this.http.post(this.baseUrl + `auth/editPortfolio`, body)
  }

  deletePortfolioImageApi(body: any) {
    return this.http.post(this.baseUrl + `user/delPortfolioImg`, body)
  }

  deleteidProofImageApi(body: any) {
    return this.http.post(this.baseUrl + `auth/deleteProof`, body)
  }

  DeleteEductionApi(body: any) {
    return this.http.post(this.baseUrl + `user/delEducation`, body)
  }

  editAvailablityApi(body: any) {
    return this.http.post(this.baseUrl + `auth/editAvailablity`, body)
  }

  DeleteworkApi(body: any) {
    return this.http.post(this.baseUrl + `user/delWorkHistory`, body)
  }


  getAllSkills() {
    return this.http.get(this.baseUrl + `api/getallSkills`)
  }

  /* ================ freelencer ================ */
  JobListingApi() {
    return this.http.get(this.baseUrl + `jobs/jobListing`,)
  }


  /* ======= client ===============*/


  CreateJobApi(body: any) {
    return this.http.post(this.baseUrl + `jobs/createJob`, body)
  }

  /* ========================  */

  clientJobListApi(body: any) {
    return this.http.post(this.baseUrl + `jobs/myJobs`, body)
  }

  jobDetail(body: any) {
    return this.http.post(this.baseUrl + `jobs/getsingleJob`, body)
  }

  applyJob(body: any) {
    return this.http.post(this.baseUrl + `jobs/applyJob`, body)
  }

  seeOtherProfile(body: any) {
    return this.http.post(this.baseUrl + `api/seeOtherProfile`, body)
  }

  applyNewsletter(body: any) {
    return this.http.post(this.baseUrl + `api/applyNewsletter`, body)
  }


  staticPage(body: any) {
    return this.http.post(this.baseUrl + 'api/getPageContent', body)
  }

  createNewSkill(body: any) {
    return this.http.post(this.baseUrl + 'user/createnewSkill', body)
  }

  searchUser(body: any) {
    return this.http.post(this.baseUrl + 'api/searchUser', body)
  }

  searchJob(body: any) {
    return this.http.post(this.baseUrl + 'api/searchJob', body)
  }

  contactUsApi(body: any) {
    return this.http.post(this.baseUrl + 'api/contactUs', body)
  }

  landingSkillsApi(body: any) {
    return this.http.get(this.baseUrl + 'api/getallSkills')
  }

  recievedPurposalApi(body: any) {
    return this.http.post(this.baseUrl + 'jobs/recievedPurposal', body)
  }

  proposaldetail(body: any) {
    return this.http.post(this.baseUrl + 'jobs/purposalDetail', body)
  }

  hirefreelencer(body: any) {
    return this.http.post(this.baseUrl + 'jobs/hireJob', body)
  }

  allContractfreelencer(body: any) {
    return this.http.post(this.baseUrl + 'jobs/freelancerAllContract', body)
  }

  proposalList() {
    return this.http.post(this.baseUrl + 'jobs/sentPurposal', '')
  }

  acceptReject(body: any) {
    return this.http.post(this.baseUrl + 'jobs/acceptRejectOffer', body)
  }

  offerDetailApi(body: any) {
    return this.http.post(this.baseUrl + 'jobs/offerDetail', body)
  }

  clienthiredfreelancer(body: any) {
    return this.http.post(this.baseUrl + 'user/showhiredFreelancers', body)
  }


  searchForClient(body: any) {
    return this.http.post(this.baseUrl + 'user/usersearchJob', body)
  }


  getOffer() {
    return this.http.post(this.baseUrl + 'jobs/getOffers', '')
  }

  completejobDetail(body: any) {
    return this.http.post(this.baseUrl + 'user/jobdetailfreelancerSide', body)
  }

  searchFreelancer(body: any) {
    return this.http.post(this.baseUrl + 'user/searchFreelancer', body)
  }

  savedFreelancer(body: any) {
    return this.http.post(this.baseUrl + 'user/saveFreelancer', body)
  }

  savefreelancerList(body: any) {
    return this.http.post(this.baseUrl + 'user/listsavedFreelancer', body)
  }

  postRatingReview(body: any) {
    return this.http.post(this.baseUrl + 'user/postReview', body)
  }

  filterFreelancerbyCat(body: any) {
    return this.http.post(this.baseUrl + 'user/filterFreelancer', body)
  }

  getReport() {
    return this.http.get(this.baseUrl + `api/getDisputeReasons`)
  }

  sendReport(body: any) {
    return this.http.post(this.baseUrl + 'jobs/disputeContract', body)

  }

  getNotificationList(body: any) {
    return this.http.post(this.baseUrl + 'user/notificationList', body)

  }

  changeNotificationReadStatus(body: any) {
    return this.http.post(this.baseUrl + 'user/changeReadStatus', body)
  }

  // question-answer api

  addQuestion(body: any) {
    return this.http.post(this.baseUrl + 'user/addQuestion', body)
  }

  getAllQuestions() {
    return this.http.get(this.baseUrl + `user/getListQuestion`)
  }

  addCommentOnQuestion(body: any) {
    return this.http.post(this.baseUrl + 'user/postquestionComment', body)
  }

  getlistOfComments(body: any) {
    return this.http.post(this.baseUrl + 'user/getListCommentOnQuestion', body)
  }

  deleteQuestion(body: any) {
    return this.http.post(this.baseUrl + 'user/deleteQuestion', body)
  }

  // mannual time

  addManualTime(body: any) {
    return this.http.post(this.baseUrl + 'user/addManualTime', body)
  }

  viewJobManualTime(body: any) {
    return this.http.post(this.baseUrl + 'user/viewJobManualTime', body)
  }

  acceptRejectManualTime(body: any) {
    return this.http.post(this.baseUrl + 'user/acceptRejectmanualHours', body)
  }


  // get work summary

  getWorkSummary(body: any) {
    return this.http.post(this.baseUrl + `user/getWeeklySummary`, body)
  }

  //getWorkDairy

  getWorkDairy(body: any) {
    return this.http.post(this.baseUrl + `user/getWeeklyDairy`, body)
  }

  viewWorkDairy(body: any) {
    return this.http.post(this.baseUrl + `user/viewWorkDairy`, body)
  }

  getProjectList(body: any) {
    return this.http.post(this.baseUrl + `jobs/projectList`, body)
  }

  previouslyHired(body: any) {
    return this.http.post(this.baseUrl + `user/previoushiredFreelancer`, body)
  }

  addMilestone(body: any) {
    return this.http.post(this.baseUrl + `jobs/addMilestone`, body)
  }

  uploadImageInChat(body: any) {
    return this.http.post(`https://php.parastechnologies.in/ondemand/api/v1/upload`, body)
  }
  requestMilestonePayment(body: any) {
    return this.http.post(this.baseUrl + `user/requestpaymentbyFreelancer`, body)
  }

  requestPaymentList(body: any) {
    // https://php.parastechnologies.in/ondemand/api/v1/user/getMilestoneRequestList
    return this.http.post(this.baseUrl + `user/getMilestoneRequestList`, body)

  }

  paymentCheckout(body: any) {
    return this.http.post(this.baseUrl + `user/paymentCheckOut`, body)

  }

  paymentStatus(body: any) {
    return this.http.post(this.baseUrl + `user/getPaymentStatus`, body)

  }

  rejectMilestoneAmount(body: any) {
    https://php.parastechnologies.in/ondemand/api/v1/user/rejectPaymentRequest
    return this.http.post(this.baseUrl + `user/rejectPaymentRequest`, body)
  }

  existingFreelancers() {
    return this.http.get(this.baseUrl + `api/getexistingFreelancer`)
  }

  initialChatStatus(body: any) {
    return this.http.post(this.baseUrl + `jobs/changeChatStart`, body)
  }

  addFreelancerBankAccount(body:any){
    return this.http.post(this.baseUrl + `user/addbankAccount`, body)
  }

  getFreelancerBankAccount(){
    return this.http.get(this.baseUrl + `user/getbankAccount`)
  }


  // downloadFile(url: string): Observable<Blob> {
  //   return this.http.get(url, { responseType: 'blob' });
  // }
  constructor(private http: HttpClient) { }
}
