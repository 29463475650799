// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl :'https://api.waathab.com/api/v1/',
  user_image:'https://api.waathab.com/public/userImages/',
  apply_job_files:'https://api.waathab.com/public/applyjobFiles/',
  GOOGLE_CLIENT_ID:'551589323793-1i0igskb8euc32totv042kika0f65r2p.apps.googleusercontent.com',
  doc_Url : 'https://api.waathab.com/public/projectImages/',
  job_files : 'https://api.waathab.com/public/jobFiles/',
  socket_media_url:'https://api.waathab.com/ondemand/public/media/',
  baseDemandUrl:'https://api.waathab.com/',
  // baseDemandUrl:'https://demandangular.harishparas.com/',
  // baseUrl :'https://php.parastechnologies.in/ondemand/api/v1/',
  // user_image:'https://php.parastechnologies.in/ondemand/public/userImages/',
  // apply_job_files:'https://php.parastechnologies.in/ondemand/public/applyjobFiles/',
  // GOOGLE_CLIENT_ID:'551589323793-1i0igskb8euc32totv042kika0f65r2p.apps.googleusercontent.com',
  // doc_Url : 'https://php.parastechnologies.in/ondemand/public/projectImages/',
  // job_files : 'https://php.parastechnologies.in/ondemand/public/jobFiles/',
  // socket_media_url:'https://php.parastechnologies.in/ondemand/public/media/',
  firebaseConfig: {
    apiKey: "AIzaSyDorISfvhSBNA5eIeW7pxL3qOqBWfQpRvY",
  authDomain: "demand-793a5.firebaseapp.com",
  projectId: "demand-793a5",
  storageBucket: "demand-793a5.appspot.com",
  messagingSenderId: "868511815264",
  appId: "1:868511815264:web:11ab713cadbdaa635dd505",
  measurementId: "G-HPKZM128Z2"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
